import styled, { keyframes } from "styled-components"

const pulse = ({ colorOne, colorTwo }) => keyframes`
  0% {
    fill: ${colorOne};
  }
  45% {
    fill: ${colorOne};
  }
  50% {
    fill: ${colorTwo};
  }
  55% {
    fill: ${colorOne};
  }
  100% {
    fill: ${colorOne};
  }
`

const SVGIcon = styled.svg`
  height: ${props => props.height || "32px"};
  min-height: ${props => props.minHeight};
  max-width: 80vw;
  cursor: pointer;
  margin: 1em;
  &:hover {
    opacity: 0.6;
  }
  fill: ${props => props.color || "black"};
  transition: all 0.2s;
`
export const PulsingSVGIcon = styled(SVGIcon)`
  animation: ${pulse({ colorOne: "white", colorTwo: "#52c476" })} 12s
    ease-in-out infinite;
`

export default SVGIcon
