import React from "react"
import styled, { ThemeProvider, createGlobalStyle } from "styled-components"
import FooterContent from "./FooterContent"

export const themeStyles = {
  colorOne: "#52c476", // Sage
  colorOneRgba: "rgba(82, 196, 118, 0.4)",
  colorTwo: "#0b4c20", // Dark Forest Green
  colorTwoRgba: "rgba(11, 76, 32, 0.4)",
  colorThree: "#D48352", // Peachy
  colorFour: "#EDEDED", // White
  grayOverlay: "rgba(38, 35, 40, 0.84)",
  mobileBreakPoint: "780px",
}

const Body = styled.div`
  min-height: 60vh;
`

const GlobalStyle = createGlobalStyle`
  html::-webkit-scrollbar  {
    display: none; 
  }
  html {
    box-sizing: border-box;
    font-size: 62.5%;
    background: ${props => props.color || "white"}
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    top: 0;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-family: 'verdana';
    font-size: 1.6rem;
  } 
  a {
    text-decoration: none;
    letter-spacing: 5px;
    color: ${themeStyles.colorFour};
    cursor: pointer;
    &:hover{
      color: ${themeStyles.colorThree}
    };
  } 
  h1 {
    letter-spacing: 4px;
    font-size: 6vw;
    text-transform: uppercase;
    opacity: 0.9;
    margin: 2vw;
    @media(max-width: 400px) {
      font-size: 34px;
    }
  }
  p {
    letter-spacing: 1.5px;
    @media (max-width: 800px) {
      font-size: 20px;
    }
  }
`

const Page = props => {
  return (
    <ThemeProvider theme={themeStyles}>
      <div>
        <div
          style={{
            height: "5px",
            width: "100vw",
            position: "fixed",
            top: "0",
            left: "0",
            background: themeStyles.colorOne,
            zIndex: 10,
          }}
        />
        <GlobalStyle color={props.color} />
        <Body>{props.children}</Body>
        <FooterContent />
      </div>
    </ThemeProvider>
  )
}

export default Page
