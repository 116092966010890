import React from "react"
import styled from "styled-components"
import SVGIcon from "./styles/SVGIcon"

const StyledFooter = styled.div`
  position: relative;
  background-color: ${props => props.backgroundColor};
  padding: 2em 0;
  width: 100vw;
  color: ${props => props.color};
  &::after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url(${props => props.backgroundImg});
    background-position: center;
    background-size: cover;
    opacity: 0.3;
    z-index: 1;
  }
  .footerContent {
    position: relative;
    width: 100vw;
    margin: auto;
    z-index: 2;
    text-align: center;
    display: grid;
    height: 100%;
    grid-template-columns: 100vw;
    grid-template-rows: 2fr 1fr;
    align-items: center;
    justify-items: center;
  }
  p.copyRight {
    width: 100%;
    line-height: 0.8em;
    text-align: center;
    opacity: 0.8;
    font-size: 0.8em;
    letter-spacing: 3px;
    color: ${props => props.color || "black"};
  }
`

const SpreadFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
`

const FooterBar = styled(SpreadFlex)`
  align-self: flex-end;
  opacity: 0.7;
  font-size: 0.8em;
  height: 100%;
  a {
    letter-spacing: 4px;
    margin: 0.5em;
  }
  @media (max-width: ${props => props.breakpoint}) {
    flex-direction: column;
    align-content: space-between;
  }
`

const ToTopButtonContainer = styled.div`
  position: absolute;
  bottom: 0vw;
  right: 8vw;
  z-index: 10;
  opacity: 0.7;
  svg {
    transform: scale(1.5);
    margin: 0;
  }

  transition: all 0.3s;
`

const ArrowSVG = ({ height, color, handleClick }) => {
  return (
    <SVGIcon
      id="upArrow"
      data-name="upArrow"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      onClick={handleClick}
      color={color}
    >
      <title>Scroll to Top</title>
      <path d="M.17,19.38A19.5,19.5,0,1,1,19.72,39,19.55,19.55,0,0,1,.17,19.38ZM21.9,32.49V17.64l.38-.24L26.77,22l3.38-3.32L19.62,8,9,18.61l3.86,3.81,4.69-5V32.49Z" />
    </SVGIcon>
  )
}

const FBsvg = ({ color, href }) => (
  <a href={href}>
    <SVGIcon
      height="40px"
      color={color}
      data-name="FB Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19.48 38"
    >
      <title>Simple Facebook Logo</title>
      <path
        d="M9.77,19.54v-.49c0-2.05,0-4.1,0-6.15,0-.43.14-.55.57-.54,1.22,0,2.44,0,3.67,0,.44,0,.59-.1.59-.56,0-1.42,0-2.84.1-4.25C15,3.65,17.34,1.22,21.33.73,23.1.51,24.9.56,26.68.51c.73,0,1.45,0,2.17,0,.29,0,.39.11.39.38q0,3.29,0,6.57c0,.34-.15.41-.46.41-1.44,0-2.89,0-4.33,0A2.24,2.24,0,0,0,22,10.05c-.05.74,0,1.48,0,2.28.19,0,.37,0,.55,0h6c.55,0,.55,0,.47.55q-.51,3.08-1,6.16a.51.51,0,0,1-.6.51c-1.62,0-3.24,0-4.85,0-.45,0-.59.12-.59.57,0,5.89,0,11.78,0,17.67,0,.68,0,.68-.68.68-2.05,0-4.09,0-6.13,0-.47,0-.61-.12-.6-.59q0-8.8,0-17.62c0-.71,0-.71-.71-.71-1.15,0-2.3,0-3.44,0C10.23,19.58,10,19.56,9.77,19.54Z"
        transform="translate(-9.76 -0.5)"
      />
    </SVGIcon>
  </a>
)

const IGsvg = ({ color, href }) => (
  <a href={href}>
    <SVGIcon
      height="40px"
      color={color}
      data-name="IG Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 38 38"
    >
      <title>Simple Instagram Logo</title>
      <path
        d="M19.35,38.47c-2.8,0-5.61.07-8.41,0A10.54,10.54,0,0,1,.61,27.8C.46,22.25.47,16.69.6,11.13.75,5.12,4.93,1,11,.74c4.47-.18,9-.29,13.43-.22a27.9,27.9,0,0,1,6.56.85,9,9,0,0,1,7,8.05A72.82,72.82,0,0,1,38.5,19,91.08,91.08,0,0,1,38,29.44a10,10,0,0,1-9.92,9c-2.9.12-5.81,0-8.71,0ZM19.43,5V5c-2.66,0-5.32-.07-8,0-3.78.13-6,2-6.53,5.18a24.5,24.5,0,0,0-.31,3.82c0,4.63.11,9.26.26,13.88a5.47,5.47,0,0,0,5.07,5.58,40.82,40.82,0,0,0,5.74.31c4.33,0,8.67,0,13-.31A5.36,5.36,0,0,0,34,28q.19-8.55,0-17.12C33.88,7.73,32.13,6,29,5.31A12.35,12.35,0,0,0,26.67,5C24.26,5,21.84,5,19.43,5Z"
        transform="translate(-0.5 -0.5)"
      />
      <path
        d="M19.41,10.45A9.52,9.52,0,1,1,10,20.1,9.38,9.38,0,0,1,19.41,10.45Zm0,4.29a5,5,0,0,0-5.21,5,5.15,5.15,0,0,0,5,5.45,5.32,5.32,0,0,0,5.26-5.11A5.11,5.11,0,0,0,19.44,14.74Z"
        transform="translate(-0.5 -0.5)"
      />
      <path
        d="M29.42,13a2.27,2.27,0,0,1-2.3-2.22,2.22,2.22,0,0,1,2.12-2.36,2.27,2.27,0,0,1,2.4,2.27A2.31,2.31,0,0,1,29.42,13Z"
        transform="translate(-0.5 -0.5)"
      />
    </SVGIcon>
  </a>
)

const Footer = ({
  children,
  backgroundImg,
  color,
  backgroundColor,
  copywright,
  breakpoint,
  facebook,
  instagram,
}) => {
  return (
    <StyledFooter
      backgroundImg={backgroundImg}
      color={color}
      backgroundColor={backgroundColor}
      id="footer"
    >
      <div className="footerContent">
        <FooterBar breakpoint={breakpoint}>
          {(facebook || instagram) && (
            <div>
              {facebook && <FBsvg color="black" href={facebook} />}
              {instagram && <IGsvg color="black" href={instagram} />}
            </div>
          )}
          {children}
        </FooterBar>
        <p className="copyRight">{copywright}</p>
        <ToTopButtonContainer>
          <ArrowSVG
            height="80px"
            color="black"
            handleClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
            }}
          />
        </ToTopButtonContainer>
      </div>
    </StyledFooter>
  )
}

export default Footer
