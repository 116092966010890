import React from "react"
import Footer from "./Footer"
import { themeStyles } from "./Page"
import ThriveabilityLogo from "./styles/ThriveabilityLogo"

const FooterComponent = () => (
  <Footer
    color="black"
    copywright="© 2019 | The Thriveability Project"
    breakpoint={themeStyles.mobileBreakPoint}
    instagram="https://www.instagram.com/thriveabilityproject"
    facebook="https://www.facebook.com/ThriveabilityProject/"
    backgroundColor={themeStyles.white}
  >
    {/* <p>Terms & Conditions</p>
    <p>Cookies & Privacy Policy</p> */}
    <ThriveabilityLogo height="180px" color="black" pulse={false} />
  </Footer>
)

export default FooterComponent
